const { assetsCdnUrl } = require('../../../client-config.js');

const offset = window.innerWidth < 480 ? '8px' : '16px';

export const FLOATING_WIDGET_POSITIONS = {
  'Top left': { top: offset, left: offset, bottom: 'initial', right: 'initial' },
  'Top right': { top: offset, left: 'initial', bottom: 'initial', right: offset },
  'Bottom right': { top: 'initial', left: 'initial', bottom: offset, right: offset },
  'Bottom left': { top: 'initial', left: offset, bottom: offset, right: 'initial' },
};

export const MINUTE_IN_MILLIS = 1000 * 60;
export const THIRTY_SECS_IN_MILLIS = 1000 * 30;
export const HOUR_IN_MILLIS = MINUTE_IN_MILLIS * 60;

export const STORAGE = {
  DISMISSED_FLOATING_WIDGET: '_bamls_dfw',
};

export const FLOATING_WIDGET_STATE = {
  CONDENSED: '_bamls_fwc',
};

export const COOKIES = {
  USER_ID: '_bamls_usid',
  SESSION_ID: '_bamls_seid',
};

export const WIDGET_EVENT_PREFIX = 'liveshopping-widget:';

export const WIDGET_EVENTS = {
  ACTION_ORIGIN: {
    FLOATING_ACTION_BUTTON: 'floating-widget',
    LANDING_PAGE: 'landing-page',
  },
  MODULE: {
    PLAYLIST_FEATURED: 'playlist-featured',
    PLAYLIST_UPCOMING: 'playlist-upcoming',
    PLAYLIST_ENDED: 'playlist-ended',
    PREVIEW_PLAYING: 'preview-playing',
    PREVIEW_PAUSED: 'preview-paused',
    CONDENSED: 'condensed',
  },
  INTERACTION_TYPE: {
    CONDENSE: 'condense',
    CLOSE: 'close',
    OPEN_PLAYER: 'open-player',
  },
  ON_LOAD: 'on-load',
  ON_INTERACTION: 'on-interaction',
  ON_PLAY: 'on-play',
  ON_PAUSE: 'on-pause',
  ON_PROGRESS: 'on-progress',
  ON_HEARTBEAT: 'on-heartbeat',
  ON_RESUME: 'on-resume',
  ON_STOP: 'on-stop',
  ON_LIVE_RESPONSE: 'on-live-response',
  SHOULD_PLAY: 'should-play',
  SIZE: 'size',
  READY: 'ready',
  PLAY: 'play',
  EMBED_CODE: 'embed-code',
  CHECK_PLAY: 'check-play',
  TRACKING: 'tracking',
  CONFIGURE: 'configure',
  FLOATING: {
    DISMISS: 'floating-dismiss',
    CONDENSE: 'floating-condense',
    EXPAND: 'floating-expand',
    NAVIGATE: 'floating-navigate',
    REPOSITION: 'floating-reposition',
    PRESENT: 'floating-present',
  },
  CHANNEL: {
    PRESENT: 'channel-present',
    ADD_TO_CALENDAR: 'add-to-calendar',
    SHARE: 'share',
  },
};

export const WIDGET_SOURCE_APPS = {
  FAB: 'fab-player-web',
};

/**
 * Widget API events
 * These are a subset of the events that are emitted by the widget
 * which are made available to the public api.
 */
export const WIDGET_API_EVENTS = {
  FLOATING: {
    PLAY: {
      name: WIDGET_EVENTS.PLAY,
    },
    DISMISS: {
      name: WIDGET_EVENTS.FLOATING.DISMISS,
      transformer: ({ id, showId }) => ({
        containerId: id,
        showId,
      }),
    },
    CONDENSE: {
      name: WIDGET_EVENTS.FLOATING.CONDENSE,
      transformer: ({ id }) => ({ id }),
    },
    EXPAND: {
      name: WIDGET_EVENTS.FLOATING.EXPAND,
      transformer: ({ id }) => ({ id }),
    },
    PRESENT: {
      name: WIDGET_EVENTS.FLOATING.PRESENT,
      constraint: (prevData, currData) =>
        // Only show if transitioning from not present to present
        prevData?.data.present !== currData.data.present,
      transformer: ({ id, present, showId, showStatus }, { position }, widgetFrame) => ({
        containerId: id,
        showId,
        present,
        showStatus,
        position: {
          initial: position,
          offset: {
            x: widgetFrame?.parentNode['data-offsets-x'] || 0,
            y: widgetFrame?.parentNode['data-offsets-y'] || 0,
          },
        },
      }),
    },
    NAVIGATE: {
      name: WIDGET_EVENTS.FLOATING.NAVIGATE,
      transformer: ({ id, showId, isLive, redirection }) => ({
        containerId: id,
        showId,
        showStatus: isLive ? 'live' : 'recorded',
        redirection,
      }),
    },

  },
  CHANNEL: {
    PLAY: {
      name: WIDGET_EVENTS.PLAY,
    },
    PRESENT: {
      name: WIDGET_EVENTS.CHANNEL.PRESENT,
      transformer: ({ id }, { playlists, localeInfo }) => ({
        containerId: id,
        locale: localeInfo?.locale || 'en-US',
        playlists,
      }),
    },
    ADD_TO_CALENDAR: {
      name: WIDGET_EVENTS.CHANNEL.ADD_TO_CALENDAR,
      transformer: ({ showId, shareUrl, showStatus, title, description, scheduledStartAt }) => ({
        showId,
        showTitle: title,
        showDescription: description,
        scheduledStartAt,
        shareUrl,
        showStatus,
      }),
    },
    SHARE: {
      name: WIDGET_EVENTS.CHANNEL.SHARE,
      transformer: ({ showId, shareUrl, showStatus, title, description, scheduledStartAt }) => ({
        showId,
        showTitle: title,
        showDescription: description,
        scheduledStartAt,
        shareUrl,
        showStatus,
      }),
    },
  },
  /**
   * Play is shared by both floating and channel widgets
   * and will override the fields set above
   */
  PLAY: {
    name: WIDGET_EVENTS.PLAY,
    transformer: ({ showId, isLive }) => ({
      showId,
      showStatus: isLive ? 'live' : 'recorded',
    }),
  },
};

export const defaultLegacyWidgetsTheme = {
  customCss: {},
  isLegacyTheme: true,
  fonts: [
    {
      family: '"Haas Grot"',
      style: 'normal',
      weight: 'normal',
      urls: [
        {
          format: 'woff2',
          url: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotText-55Roman-Web.woff2`,
        },
        {
          format: 'woff',
          url: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotText-55Roman-Web.woff`,
        },
      ],
    },
    {
      family: '"Haas Grot Display"',
      style: 'normal',
      weight: 'normal',
      urls: [
        {
          format: 'woff2',
          url: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotDisp-65Medium-Web.woff2`,
        },
        {
          format: 'woff',
          url: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotDisp-65Medium-Web.woff`,
        },
      ],
    },
  ],
  styles: {
    base: {
      bgColor: '#fff',
      fontFamily: '"Haas Grot", Helvetica, Arial, Verdana, Tahoma, sans-serif',
      textColor: '#000',
      textColorSecondary: '#747474',
      fontSizeSecondary: '14px',
      borderRadius: '0',
    },
    button: {
      primary: {
        textColor: '#fff',
        borderColor: '#000',
        fontWeight: '400',
        textTransform: 'uppercase',
        bgColor: '#000',
        radius: '0',
      },
      secondary: {
        bgColor: '#fff',
        textColor: '#000',
        borderColor: '#000',
        radius: '0',
        textTransform: 'uppercase',
        fontWeight: '400',
      },
    },
    curtain: {
      pending: {
        textTransform: 'none',
        bgColor: '#000',
        textColor: '#fff',
      },
    },
    headings: {
      fontFamily: '"Haas Grot Display", Helvetica, Arial, Verdana, Tahoma, sans-serif',
      h1: {
        fontColor: '#000',
        fontSize: '48px',
        fontWeight: '400',
        textTransform: 'none',
      },
      h2: {
        fontColor: '#000',
        fontSize: '48px',
        fontWeight: '400',
        textTransform: 'none',
      },
      mobile: {
        h1: {
          fontSize: '32px',
        },
        h2: {
          fontSize: '24px',
        },
      },
    },
    label: {
      live: {
        backgroundColor: '#E12147',
        fontSize: '12px',
        fontWeight: 'normal',
        textColor: '#fff',
        textTransform: 'uppercase',
      },
    },
    dropdown: {
      menu: {
        backgroundColor: '#fff',
        fontSize: '14px',
        fontWeight: '400',
        borderRadius: '0px',
        color: '#000',
      },
      icon: {
        color: '#000',
      },
    },
  },
};

export const general = {
  general: {
    fonts: {
      fonts: {
        manageFonts: [
          {
            filename: 'NeueHaasGrotText-55Roman-Web.woff',
            format: 'woff',
            id: 'font1',
            name: 'Haas Grot',
            storageUrl: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotText-55Roman-Web.woff`,
            style: 'normal',
            weight: '400',
          },
          {
            filename: 'NeueHaasGrotText-55Roman-Web.woff2',
            format: 'woff2',
            id: 'font2',
            name: 'Haas Grot',
            storageUrl: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotText-55Roman-Web.woff2`,
            style: 'normal',
            weight: '400',
          },
          {
            filename: 'NeueHaasGrotDisp-65Medium-Web.woff',
            format: 'woff',
            id: 'font3',
            name: 'Haas Grot Display',
            storageUrl: `${assetsCdnUrl}//fonts/neue-haas-grotesk/NeueHaasGrotDisp-65Medium-Web.woff`,
            style: 'normal',
            weight: '500',
          },
          {
            filename: 'NeueHaasGrotDisp-65Medium-Web.woff',
            format: 'woff',
            id: 'font4',
            name: 'Haas Grot Display',
            storageUrl: `${assetsCdnUrl}/fonts/neue-haas-grotesk/NeueHaasGrotDisp-65Medium-Web.woff`,
            style: 'normal',
            weight: '500',
          },
        ],
        primaryFont: 'Haas Grot',
        secondaryFont: 'Haas Grot Display',
      },
    },
  },
};

export const themingAPIChannelsTheme = {
  styles: {
    ...general,
    channels: {
      buttons: {
        main: {
          fontSize: '14px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          borderRadius: '4px',
        },
        primary: {
          backgroundColor: 'blue',
          borderColor: 'blue',
          color: '#000000',
        },
        secondary: {
          backgroundColor: '#000',
          borderColor: 'red',
          color: '#fff',
        },
      },
      curtains: {
        curtainPending: {
          backgroundColor: '#000',
          color: '#fff',
          textTransform: 'uppercase',
        },
      },
      selects: {
        menu: {
          backgroundColor: '#fff',
          fontSize: '12px',
          fontWeight: 'bold',
          borderRadius: '4px',
          textTransform: 'none',
          label: {
            color: '#000',
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        icon: {
          color: '#2a2a2a',
        },
      },
      page: {
        main: {
          backgroundColor: '#fff',
          borderRadius: '4px',
          fontFamily: 'Haas Grot',
          primaryText: {
            color: '#000',
          },
          secondaryText: {
            color: '#747474',
            fontSize: '13.5px',
          },
        },
        headings: {
          fontFamily: 'Haas Grot Display',
          h1Headings: {
            color: '#000',
            fontSize: '36px',
            fontWeight: 'bold',
            textTransform: 'none',
          },
          h1HeadingsMobile: {
            fontSize: '24px',
          },
          h2Headings: {
            color: '#000',
            fontSize: '24px',
            fontWeight: 'bold',
            textTransform: 'none',
          },
          h2HeadingsMobile: {
            fontSize: '18px',
          },
        },
      },
      statusBar: {
        statusLive: {
          backgroundColor: '#e12147',
          color: '#fff',
          fontSize: '12px',
          textTransform: 'none',
          fontWeight: 'bold',
          fontFamily: 'Haas Grot',
        },
      },
    },
  },
};

export const themingAPIFABTheme = {
  styles: {
    ...general,
    floatingActionButton: {
      curtains: {
        curtainPending: {
          backgroundColor: '#000',
          color: '#fff',
        },
      },
      main: {
        button: {
          borderRadius: '0px',
          fontFamily: 'Haas Grot',
        },
      },
      statusBar: {
        statusLive: {
          backgroundColor: '#E12147',
          color: '#fff',
          fontFamily: 'Haas Grot',
          fontSize: '12px',
          fontWeight: 'normal',
          textTransform: 'uppercase',
        },
      },
    },
  },
};
